import React from "react";
import GetElementType from "./Elements";

const Outputpage = ({ mainjson, setcurrentid, printRef }) => {
  return (
    <>
      <div ref={printRef} className="mt-4 p-2 editorOutputright">
        {mainjson.length > 0 &&
          mainjson.map((v, i) => {
            return (
              <div onDoubleClick={() => setcurrentid(v.id)} key={i}>
                <GetElementType json={v} />
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Outputpage;
