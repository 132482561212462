import React from "react";

const TitleCover = ({ json }) => {
  return (
    <div className="titleCover_root mb-24 coverMargin" id={json.bookmarkId}>
      <div class="titleCover_gradient"></div>
      <img alt="test" src={json.image} />
      <div className="titleCover_info">
        <p className="editorCaption d-flex align-items-center">
          {json.topic}{" "}
          <span
            style={{
              width: 6,
              height: 6,
              borderRadius: "50%",
              margin: "0 6px",
              background:"#000"
            }}
          ></span>{" "}
          {new Date(json.coverdate).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          })}
        </p>
        <p className="editorh2">{json.content}</p>
      </div>
    </div>
  );
};

export default TitleCover;
