import React from 'react'

const ArrowDown = ({cc}) => {
  return <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      fill="none"
      viewBox="0 0 32 33"
    >
      <path fill={cc ? cc : "#757575"} d="M1 10.558l15 11.886 15-11.886H1z"></path>
    </svg>
}

export default ArrowDown