import React, { useEffect, useState } from "react";
import "./index.css";
import axios from "axios";
import { nanoid } from "nanoid";
import AddBigIcon from "../../../Assets/Images/AddBig.svg";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";

const BlogsImages = ({ onClickFunc }) => {
  const [imagesArr, setImagesArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  
  const getAllImages = async () => {
    setLoading(true);
    await axios({
      method: "get",
      url: `/api/v1/blog/allbucketimages`,
    })
      .then((res) => {
        setLoading(false);
        setImagesArr(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const uploadImage = async (e) => {
    const image = e.target.files[0];
    if (image.size > 2048 * 1024) {
      toast.error("File size exceeded 2MB!", { position: "bottom-right" });
      return;
    }
    const formdata = new FormData();
    formdata.append("blogcover", image);
    setUploading(true);
    axios({
      method: "post",
      url: "/api/v1/blog/uploadblogimage",
      data: formdata,
    })
      .then((res) => {
        const { data } = res.data;
        setImagesArr((prev)=>[data, ...prev])
        setUploading(false);
        toast.success("Profile Image upload", { position: "bottom-right" });
      })
      .catch(() => {
        setUploading(false);
        toast.error("Error in upload", { position: "bottom-right" });
      });
  };

  useEffect(() => {
    getAllImages();
  }, []);
  return (
    <div className="blogs-images-root">
      {loading ? (
        <h3>Loading...</h3>
      ) : (
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-24">
            {uploading ? (
              <CircularProgress />
            ) : (
              <label htmlFor="addImage" className="pointer">
                <input
                  type="file"
                  name="addImage"
                  id="addImage"
                  hidden
                  accept="image/png, image/gif, image/jpg, image/jpeg .png, .jpg, .jpeg"
                  onChange={uploadImage}
                />
                <img className="img-fluid" src={AddBigIcon} alt="icon" />
              </label>
            )}
          </div>
          {imagesArr &&
            imagesArr.length > 0 &&
            imagesArr.map((url) => (
              <div
                className="col-lg-3 col-md-4 col-sm-6 col-6 mb-24"
                key={nanoid()}
              >
                <div
                  className="blogs-images-main"
                  onClick={() => onClickFunc(url)}
                >
                  <img src={url} alt={nanoid()} className="img-fluid pointer" />
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default BlogsImages;
