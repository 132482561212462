import { CircularProgress } from "@mui/material";
import React, { useEffect } from "react";

const trendisttopics = {
  Wackenhut: ["HR News", "Innovation im Autohandel"],
  "Senat Europa": [
    "Cybersecurity",
    "Geopolitical situation (in the Energy Sector)",
  ],
};

const InputElement = ({
  handleAddElement,
  week,
  setweek,
  topic,
  settopic,
  company,
  setcompany,
  elementType,
  setelementType,
  content,
  setcontent,
  currentid,
  mainjson,
  setcurrentid,
  deleteElement,
  handlePicUpload,
  image,
  setimage,
  listcontents,
  setlistcontents,
  newbullet,
  setnewbullet,
  paraimage,
  setParaimage,
  pageBreak,
  setPageBreak,
  bookmarkId,
  setBookmarkId,
  coverdate,
  setCoverDate,
  isUploading,
  handleImageUpload,
}) => {
  useEffect(() => {
    if (currentid) {
      settopic(mainjson.find((v) => v.id === currentid)?.topic);
      setweek(mainjson.find((v) => v.id === currentid)?.week);
      setcompany(mainjson.find((v) => v.id === currentid)?.company);
      setelementType(mainjson.find((v) => v.id === currentid)?.elementType);
      setcontent(mainjson.find((v) => v.id === currentid)?.content);
      setimage(mainjson.find((v) => v.id === currentid)?.image);
      setParaimage(mainjson.find((v) => v.id === currentid)?.paraimage);
      setlistcontents(mainjson.find((v) => v.id === currentid)?.listcontents);
      setPageBreak(mainjson.find((v) => v.id === currentid)?.pageBreak);
      setBookmarkId(mainjson.find((v) => v.id === currentid)?.bookmarkId);
      setCoverDate(mainjson.find((v) => v.id === currentid)?.coverdate);
    }
  }, [currentid]);

  const handleRemoveItem2 = (id) => {
    setlistcontents((mails) => mails.filter((_, index) => index !== id));
  };

  return (
    <div className="editorOutputright">
      <form className="trendist_editor_form" onSubmit={handleAddElement}>
        <div class="form-group mt-2">
          <label className="body1 mb-8">Add Element</label>
          <select
            value={elementType}
            onChange={(e) => {
              if (!currentid) setcontent("");
              if (e.target.value === "page-break") {
                setPageBreak(true);
              }
              setelementType(e.target.value);
            }}
            class="form-select body2 p-8"
            aria-label="Default select example"
          >
            <option value="">Select Next Element to be added</option>
            <option value="page-break">Page Break </option>
            <option value="mainheading">Main Heading</option>
            <option value="indextopic">Index Topics </option>
            <option value="titlecover">Cover Image Headline </option>
            <option value="boldcontent">Bold Heading</option>
            <option value="paragraph">Paragraph</option>
            <option value="bulletlist">Bullet List</option>
            <option value="hyperlink">Hyperlink Text</option>
            <option value="image">Image </option>
          </select>
        </div>
        <div className="mt-24">
          {elementType === "mainheading" ? (
            <div>
              <div class="mb-3 ">
                <label for="exampleInputPassword1" class="form-label">
                  Select Company
                </label>
                <select
                  onChange={(e) => setcompany(e.target.value)}
                  value={company}
                  class="form-select"
                  required
                  aria-label="Default select example"
                >
                  <option value="">Choose Topic</option>
                  {Object.keys(trendisttopics).map((v, i) => {
                    return (
                      <>
                        <option key={i} value={v}>
                          {v}
                        </option>
                      </>
                    );
                  })}
                </select>
              </div>
              <div class="mb-3 ">
                <label for="exampleInputPassword1" class="form-label">
                  Select Topic
                </label>
                <select
                  onChange={(e) => settopic(e.target.value)}
                  value={topic}
                  required
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option value="">Choose Topic</option>
                  {trendisttopics[company] &&
                    trendisttopics[company].map((v, i) => {
                      return (
                        <>
                          <option key={i} value={v}>
                            {v}
                          </option>
                        </>
                      );
                    })}
                </select>
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  Week number
                </label>
                <input
                  onChange={(e) => setweek(e.target.value)}
                  value={week}
                  type="number"
                  class="form-control"
                  id="exampleInputPassword1"
                />
              </div>
            </div>
          ) : elementType === "paragraph" ? (
            <div>
              <label className="mb-8">Enter Paragraph content</label>
              <textarea
                onChange={(e) => setcontent(e.target.value)}
                value={content}
                type="text"
                class="h-auto trendist_translate form-control rounded-0"
              ></textarea>
            </div>
          ) : elementType === "indextopic" ? (
            <div>
              <div className="mb-12">
                <label className="mb-8">Source</label>
                <input
                  onChange={(e) => settopic(e.target.value)}
                  value={topic}
                  type="text"
                  class="h-auto trendist_translate form-control rounded-0"
                ></input>
              </div>
              <div className="mb-12">
                <label className="mb-8">Bookmark Id</label>
                <input
                  onChange={(e) => setBookmarkId(e.target.value)}
                  value={bookmarkId}
                  type="text"
                  class="h-auto trendist_translate form-control rounded-0"
                ></input>
                {/* <small></small> */}
              </div>
              <div className="mb-12">
                <label className="mb-8">Article Name</label>
                <textarea
                  onChange={(e) => setcontent(e.target.value)}
                  value={content}
                  type="text"
                  class="h-auto trendist_translate form-control rounded-0"
                ></textarea>
              </div>
              {image ? (
                <div class="form-group ">
                  <img
                    height="100px"
                    width="100px"
                    src={image}
                    alt="cover"
                  />
                  <br />
                  <p style={{ cursor: "pointer" }} onClick={() => setimage()}>
                    Remove
                  </p>
                </div>
              ) : (
                <div class="form-group ">
                  <label htmlFor="coverImage">Upload Image</label>
                  <input
                    type="file"
                    accept="image/*"
                    id="templateImages"
                    onChange={handlePicUpload}
                  />
                </div>
              )}
              {isUploading && <CircularProgress />}
            </div>
          ) : elementType === "titlecover" ? (
            <div>
              <label>Enter Title</label>
              <input
                onChange={(e) => settopic(e.target.value)}
                value={topic}
                type="text"
                class="h-auto trendist_translate form-control rounded-0"
              ></input>
              <label>Enter Bookmark id</label>
              <input
                onChange={(e) => setBookmarkId(e.target.value)}
                value={bookmarkId}
                type="text"
                class="h-auto trendist_translate form-control rounded-0"
              ></input>
              <label>Enter Date</label>
              <input
                onChange={(e) => setCoverDate(e.target.value)}
                value={coverdate}
                type="date"
                class="h-auto trendist_translate form-control rounded-0"
              ></input>
              <label>Enter Headline</label>
              <textarea
                onChange={(e) => setcontent(e.target.value)}
                value={content}
                type="text"
                class="h-auto trendist_translate form-control rounded-0"
              ></textarea>
              {image ? (
                <div class="form-group ">
                  <img
                    height="100px"
                    width="100px"
                    src={image}
                    alt="cover"
                  />
                  <br />
                  <p style={{ cursor: "pointer" }} onClick={() => setimage()}>
                    Remove
                  </p>
                </div>
              ) : (
                <div class="form-group ">
                  <label htmlFor="coverImage">Upload Image</label>
                  <input
                    type="file"
                    accept="image/*"
                    id="templateImages"
                    onChange={handlePicUpload}
                  />
                </div>
              )}
              {isUploading && <CircularProgress />}
            </div>
          ) : elementType === "boldcontent" ? (
            <div>
              <label>Enter Bold content</label>
              <textarea
                onChange={(e) => setcontent(e.target.value)}
                value={content}
                type="text"
                class="h-auto trendist_translate form-control rounded-0"
              ></textarea>
            </div>
          ) : elementType === "bulletlist" ? (
            <div>
              <label>Enter Bullet List contents</label>
              {listcontents &&
                listcontents.map((v, i) => {
                  return (
                    <>
                      <ul role="toolbar">
                        <li>
                          {v}
                          <button
                            onClick={() => handleRemoveItem2(i)}
                            style={{ backgroundColor: "red", color: "white" }}
                            type="button"
                          >
                            X
                          </button>
                        </li>
                      </ul>
                    </>
                  );
                })}
              <div className="mb-3">
                <textarea
                  onChange={(e) => setnewbullet(e.target.value)}
                  value={newbullet}
                  placeholder="Add Content"
                  type="text"
                  class="h-auto trendist_translate form-control rounded-0"
                />
                <button
                  style={{ backgroundColor: "green", color: "white" }}
                  onClick={() => {
                    if (newbullet.trim().length !== 0)
                      setlistcontents((v) => [...v, newbullet]);
                    setnewbullet("");
                  }}
                  className="p-2"
                  type="button"
                >
                  Add
                </button>
              </div>
            </div>
          ) : elementType === "hyperlink" ? (
            <div>
              <label>Text to Display</label>
              <input
                onChange={(e) => settopic(e.target.value)}
                value={topic}
                type="text"
                required
                class="h-auto trendist_translate form-control rounded-0"
              ></input>
              <label>Enter Link address</label>
              <input
                onChange={(e) => setcontent(e.target.value)}
                value={content}
                type="url"
                required
                class="h-auto trendist_translate form-control rounded-0"
              ></input>
            </div>
          ) : elementType === "image" ? (
            <div>
              {paraimage.url ? (
                <div>
                  <div className="row">
                    <div className="col">
                      <p className="editorTitle1">Size</p>
                      <input
                        type="number"
                        class="h-auto trendist_translate form-control rounded-0"
                        placeholder="100%"
                        value={paraimage.size}
                        onChange={(e) =>
                          setParaimage({ ...paraimage, size: e.target.value })
                        }
                      />
                    </div>
                    <div className="col">
                      <p className="editorTitle1">Align</p>
                      <select
                        class="form-select body2 p-8"
                        aria-label="Default select example"
                        value={paraimage.align}
                        onChange={(e) =>
                          setParaimage({ ...paraimage, align: e.target.value })
                        }
                      >
                        <option value="left">left</option>
                        <option value="center">center</option>
                        <option value="right">right</option>
                      </select>
                    </div>
                  </div>
                  <div
                    class="form-group mt-8 mb-8"
                    style={{ textAlign: paraimage.align }}
                  >
                    <img
                      height="auto"
                      width={`${paraimage.size}%`}
                      className=""
                      src={paraimage.url}
                      alt="cover"
                    />
                    <br />
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setParaimage({
                          url: "",
                          size: 100,
                          align: "left",
                        })
                      }
                    >
                      Remove
                    </p>
                  </div>
                </div>
              ) : (
                <div class="form-group ">
                  <label htmlFor="coverImage">Upload</label>
                  <input
                    type="file"
                    accept="image/*"
                    id="templateImages"
                    onChange={handleImageUpload}
                  />
                </div>
              )}
              {isUploading && <CircularProgress />}
            </div>
          ) : (
            elementType === "page-break" && (
              <div>
                <h6>Are you sure you want to add page break?</h6>
                <div className="d-flex align-items-center mt-24">
                  <button
                    className="zefy_CTA_01_outline"
                    onClick={() => {
                      setPageBreak(false);
                      setelementType("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="ml-24 zefy_CTA_01"
                    onClick={handleAddElement}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            )
          )}
        </div>
        <button type="submit" class="zefy_CTA_01 mt-12">
          {currentid ? "Update Element" : "Create Element"}
        </button>
        {currentid && (
          <button
            onClick={() => {
              deleteElement();
              setcontent("");
              setelementType("");
            }}
            class="zefy_CTA_01 ml-12"
            style={{ backgroundColor: "red" }}
          >
            Delete Element
          </button>
        )}
      </form>
      {currentid && (
        <button
          onClick={() => setcurrentid()}
          class="btn btn-warning p-3 mt-2 mb-24"
        >
          Close Editing Mode
        </button>
      )}
    </div>
  );
};

export default InputElement;
