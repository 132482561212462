import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import get_diff from "../../Utils/eventDiffrence";

const Schedules = () => {
  const [logs, setlogs] = useState([]);
  const [isloading, setisloading] = useState(false);
  const getlogs = async () => {
    try {
      setisloading(true);
      const response = await axios.get("/api/v1/mail/allschedules");
      if (response.data.success) {
        setlogs(response.data.data);
        setisloading(false);
      }
    } catch (error) {
      console.log(error);
      setisloading(false);

      if (error.response && error.response.data) {
        toast.error(error.response.data.error);
      }
    }
  };

  const handledelete = async (id) => {
    try {
      const response = await axios.get(`/api/v1/mail/deleteschedule/${id}`);
      if (response.data.success) {
        toast.success("Email schedule removed");
        getlogs();
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) {
        toast.error(error.response.data.error);
      }
    }
  };
  useEffect(() => {
    getlogs();
  }, []);

  return (
    <table class="table trendistmailform">
      <thead>
        <tr>
          <th scope="col">Trendist</th>
          <th scope="col">Status</th>
          <th scope="col">TimeLeft</th>
          <th scope="col">Type</th>
          <th scope="col">Subject</th>
          <th scope="col">Topic</th>
          <th scope="col">Week</th>
          <th scope="col">Emails</th>
          <th scope="col">Template</th>
          <th scope="col">Remove</th>
        </tr>
      </thead>
      {isloading ? (
        <p>
          Loading schedules ... <CircularProgress />
        </p>
      ) : null}
      <tbody>
        {logs.length > 0 &&
          logs.map((v, i) => {
            return (
              <tr>
                <th scope="row">{i + 1}</th>
                <td>
                  {v.lastRunAt ? (
                    <span style={{ color: "green" }}>
                      Emails sent at {new Date(v.lastRunAt).toString()}
                    </span>
                  ) : v.nextRunAt ? (
                    <span style={{ color: "blue" }}>
                      Scheduled at {new Date(v.nextRunAt).toString()}
                    </span>
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  {v.lastRunAt ? (
                    <span style={{ color: "green" }}>SENT</span>
                  ) : v.nextRunAt ? (
                    <span style={{ color: "darkred" }}>
                      {get_diff(v.nextRunAt)}
                    </span>
                  ) : (
                    ""
                  )}
                </td>
                {v.data.live === "true" ? (
                  <td style={{ color: "green", fontSize: "20px" }}>Live</td>
                ) : (
                  <td style={{ color: "red", fontSize: "20px" }}>Testing</td>
                )}
                <td>{v.data.subject} </td>
                <td>{v.data.topic} </td>
                <td>{v.data.week} </td>
                <td>{v.data.emails && v.data.emails.map((v, i) => `${v},`)}</td>
                <td
                  style={{ color: "blue" }}
                  className="pointer"
                  onClick={() => window.open(v.data.file?.url)}
                >
                  View
                </td>
                <td
                  style={{ color: "red" }}
                  className="pointer"
                  onClick={() => handledelete(v.data.id)}
                >
                  Delete Schedule
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default Schedules;
