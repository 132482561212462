import React from "react";
// import ArticleImg from "../../Assets/Images/article.png";
import Axios from "axios";

const Article = ({ article, i }) => {
  const handleView = async(id) => {
    await Axios({
      url: `/api/v1/article/views/${id}`,
      method:"put"
    }).catch((err)=>{
      console.log(err)
    })
  }
  return (
    <div className={`dashboard-articlepage-root mb-64`}>
      <div
        className={`dashboard-article-main2 ${
          i === 0 ? "dashboard-topborder" : ""
        } `}
      >
        <div
          className={`row justify-content-center ${i === 0 ? " mt-64" : ""}`}
        >
          <div className="col-lg-7 col-md-8 col-sm-10 col-12">
            <div className="article-page-main">
              <div className="article-img">
                <img src={article?.cover} alt="article" />
              </div>
              <div className="article-main">
                <p className="article-link caption color_grey_46">
                 {article?.source}
                </p>
                <p className="article-main-title mt-8" onClick={()=>handleView(article._id)}>
                  <a
                    href={article?.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {article?.title}
                    <span className="ml-12" style={{ color: "#4AD7D1" }}>
                      ➤
                    </span>{" "}
                  </a>
                </p>
                <p className="article-desc mt-24 grey_75">
                  {article?.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Article;
