import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import EmailForm from "./Companies/Form";
import Logs from "./Logs";
import Schedules from "./schedules";
import { useAuth } from "../../Utils/Auth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function TrendistEmails() {
  const [value, setValue] = React.useState(0);
  const { isAdmin } = useAuth();
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (!isAdmin) {
      navigate(`/admin/login`);
    }
  }, [isAdmin]);

  if (isAdmin)
    return (
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: "auto",
        }}
        className="mail_dashboard"
      >
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider", minWidth: "200px" }}
        >
          <Tab label="All Mail Schedules" {...a11yProps(0)} />
          <Tab label="Mail logs" {...a11yProps(1)} />
          {/* <Tab label="Linde Mails" {...a11yProps(2)} /> */}
          <Tab label="Wackenhut Mails" {...a11yProps(2)} />
          <Tab label="Wieland Mails" {...a11yProps(4)} />
          <Tab label="Senate Mails" {...a11yProps(5)} />
          <Tab label="AMG" {...a11yProps(6)} />
          <Tab label="Bosch" {...a11yProps(7)} />

          {/* <Tab label="Liebherr" {...a11yProps(7)} />
          <Tab label="Project Bio" {...a11yProps(7)} /> */}
        </Tabs>
        <TabPanel
          style={{ overflow: "scroll", maxWidth: "80vw" }}
          className="w-100"
          value={value}
          index={0}
        >
          <Schedules />
        </TabPanel>
        <TabPanel className="w-100" value={value} index={1}>
          <Logs />
        </TabPanel>
        {/* <TabPanel className="w-100" value={value} index={2}>
          <EmailForm company={"Linde"} />
        </TabPanel> */}
        <TabPanel className="w-100" value={value} index={2}>
          <EmailForm company={"Wackenhut"} />
        </TabPanel>
        <TabPanel className="w-100" value={value} index={3}>
          <EmailForm company={"Wieland"} />
        </TabPanel>
        <TabPanel className="w-100" value={value} index={4}>
          <EmailForm company={"Senat Europa"} />
        </TabPanel>
        <TabPanel className="w-100" value={value} index={5}>
          <EmailForm company={"AMG"} />
        </TabPanel>
        <TabPanel className="w-100" value={value} index={6}>
          <EmailForm company={"Bosch"} />
        </TabPanel>
        {/* <TabPanel className="w-100" value={value} index={7}>
          <EmailForm company={"Liebherr"} />
      */}

        {/* <TabPanel className="w-100" value={value} index={8}>
          <EmailForm company={"ProjectBio"} />
        </TabPanel> */}
      </Box>
    );
}
