import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Login from "./Pages/Admin/Authentication/Login";
import AdminDashboard from "./Pages/Admin/AdminDashboard";
import Dashboard from "./Pages/Dashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Admincheck from "./Pages/Admin/AdminCheck/Admincheck";
import { ProvideAuth } from "./Utils/Auth";
import "./App.css";
import Home from "./Pages/Home";
import Check from "./Pages/check/Check";
import ArticlePage from "./Pages/ArticlePage/ArticlePage";
import ErrorPage from "./Pages/404Pages/ErrorPage";
import Articles from "./Pages/Admin/Articles/Articles";
import Published from "./Pages/Admin/Published/Published";
import Preview from "./Pages/Admin/Preview/Preview";
import CreateTopic from "./Pages/Admin/Topic/CreateTopic";
import TrendistEmails from "./Pages/TrendistEmails";
import AdminNavbar from "./Components/Navbar/AdminNavbar";
import ViewDashboard from "./Pages/ViewDashboard/ViewDashboard";
import UserAdminLogin from "./Pages/UserAdminLogin/UserAdminLogin";
import UserAdminCheck from "./Pages/UserAdminCheck/UserAdminCheck";
import Translate from "./Pages/Translate/Translate";
import Editor from "./Pages/Translate/Editor";
import AllDocs from "./Pages/Translate/AllDocs";
import BlogEditor from "./Pages/BlogEditor"
import AllBlogs from "./Pages/BlogEditor/AllBlogs";
import FundingNews from "./Pages/FundingNews";

const App = () => {
  return (
    <div className="App">
      <ToastContainer
        style={{
          fontSize: "1.6rem",
        }}
      />
      <Router>
        <ProvideAuth>
          <Routes>
            <Route exact path="/wvib/:topic" element={<Home />} />
            <Route exact path="/wvib/admin/views" element={<ViewDashboard />} />
            <Route
              exact
              path="/wvib/admin/login"
              element={<UserAdminLogin />}
            />
            <Route exact path="/dashboard/:topic" element={<Dashboard />} />
            <Route exact path="/check" element={<Check />} />
            <Route exact path="/article/:week/:id" element={<ArticlePage />} />
            <Route exact path="/admin/login" element={<Login />} />
            <Route exact path="/admin/dashboard" element={<AdminDashboard />} />
            <Route exact path="/admin/articles" element={<Articles />} />
            <Route exact path="/admin/published" element={<Published />} />
            <Route exact path="/admin/preview/:id" element={<Preview />} />
            <Route exact path="/admin/add/topic" element={<CreateTopic />} />
            <Route exact path="/admin-check" element={<Admincheck />} />
            <Route
              exact
              path="/user-admin-check"
              element={<UserAdminCheck />}
            />
            <Route
              exact
              path="/admin/trendist-mails"
              element={
                <>
                  <AdminNavbar /> <TrendistEmails />
                </>
              }
            />
            <Route
              exact
              path="/admin/translate"
              element={
                <>
                  <AdminNavbar /> <Translate />
                </>
              }
            />
            <Route
              exact
              path="/admin/editor"
              element={
                <>
                  <AdminNavbar /> <Editor />
                </>
              }
            />
            <Route
              exact
              path="/admin/blogeditor"
              element={
                <>
                  <AdminNavbar /> <BlogEditor />
                </>
              }
            />
            <Route
              exact
              path="/admin/funding-news"
              element={
                <>
                  <AdminNavbar /> <FundingNews />
                </>
              }
            />
            <Route
              exact
              path="/admin/blogeditor/:userid"
              element={
                <>
                  <AdminNavbar /> <BlogEditor />
                </>
              }
            />
            <Route
              exact
              path="/admin/editor/:id"
              element={
                <>
                  <AdminNavbar /> <Editor />
                </>
              }
            />
            <Route
              exact
              path="/admin/all/docs"
              element={
                <>
                  <AdminNavbar /> <AllDocs />
                </>
              }
            />
            <Route
              exact
              path="/admin/all/blogs"
              element={
                <>
                  <AdminNavbar /> <AllBlogs />
                </>
              }
            />
            <Route exact path="*" element={<ErrorPage />} />
          </Routes>
        </ProvideAuth>
      </Router>
    </div>
  );
};

export default App;
