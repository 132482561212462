import Axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminNavbar from "../../../Components/Navbar/AdminNavbar";
// import { useAuth } from "../../../Utils/Auth";

const Preview = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState(null);
  // const { isAdmin } = useAuth();
  const getArticleById = async (id) => {
    await Axios({
      url: `/api/v1/article/${id}`,
      method: "get",
    })
      .then((res) => {
        setArticle(res.data.data);
      })
      .catch((err) => {
        toast.error("Error Occured");
        navigate(-1);
      });
  };
  const publishArticle = async (id) => {
    await Axios({
      url: `/api/v1/article/pubslish/${id}`,
      method: "put",
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Article Published");
          getArticleById(id);
        }
      })
      .catch(() => toast.error("Error Occured"));
  };
  useEffect(() => {
    if (id) {
      getArticleById(id);
    }
     // eslint-disable-next-line 
  }, [id]);
  // useEffect(() => {
  //   if (!isAdmin) {
  //     navigate(`/admin/login`);
  //   }
  // }, [isAdmin]);
  return (
    <div className="articlePage-root">
      <AdminNavbar />
      <div className="article-page_head">
        <div className="article-page_back d-flex align-items-center">
          <p className="back_p pointer" onClick={() => navigate(-1)}>
            <span className="mr-24"></span> Back
          </p>
        </div>
      </div>
      <div className="dashboard-main">
        <div className={`dashboard-articlepage-root mb-64`}>
          <div className={`dashboard-article-main2 dashboard-topborder`}>
            {article && (
              <div className={`row justify-content-center mt-64`}>
                <div className="col-lg-7 col-md-8 col-sm-10 col-12">
                  <div className="article-page-main">
                    <div className="article-img">
                      <img src={article?.cover} alt="article" />
                    </div>
                    <div className="article-main">
                      <p className="article-link caption color_grey_46">
                        {article?.source}
                      </p>
                      <p className="article-main-title mt-8">
                        <a
                          href={article?.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {article?.title}
                          <span className="ml-12" style={{ color: "#4AD7D1" }}>
                            ➤
                          </span>{" "}
                        </a>
                      </p>
                      <p className="article-desc mt-24 grey_75">
                        {article?.description}
                      </p>
                    </div>
                    {article.isPublish ? null : (
                      <div className="mt-24">
                        <button
                          className="zefy_CTA_04_md_fill_width"
                          onClick={() => publishArticle(article._id)}
                        >
                          Publish
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preview;
