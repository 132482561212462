import React, { useEffect, useState } from "react";
import "./index.css";
import { Delete, Edit, Visibility } from "@mui/icons-material/";
import Axios from "axios";
import { toast } from "react-toastify";
import AdminNavbar from "../../../Components/Navbar/AdminNavbar";
import { Link, useNavigate } from "react-router-dom";
import ConfirmModal from "../../../Components/ConfirmModal";
import useQuery from "../../../Utils/querry";

const Articles = () => {
  const [unpublishedArticles, setUnpublishedArticles] = useState([]);
  const [open, setOpen] = useState(false);
  const [week, setWeek] = useState(1);
  const [allTopics, setAllTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [id, setId] = useState(null);
  const query = useQuery();
  const weekNo = Number(query.get("week"));

  const navigate = useNavigate();
  const handleOpen = (id) => {
    setId(id);
    setOpen(true);
  };
  const getTopics = async () => {
    await Axios({
      url: "/api/v1/topic/admin/all",
      method: "get",
    })
      .then((res) => {
        setAllTopics(res.data.data);
      })
      .catch(() => toast.error("Error Occured"));
  };
  const getArtcles = async () => {
    await Axios({
      url: "/api/v1/article/unpublished/all",
      method: "get",
    })
      .then((res) => {
        setUnpublishedArticles(res.data.data);
      })
      .catch(() => toast.error("Error Occured"));
  };
  const publishArticle = async (id) => {
    await Axios({
      url: `/api/v1/article/pubslish/${id}`,
      method: "put",
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Article Published");
          getArtcles();
        }
      })
      .catch(() => toast.error("Error Occured"));
  };

  useEffect(() => {
    getArtcles();
    getTopics();
  }, []);

  useEffect(() => {
    if (Number(weekNo)) {
      setWeek(weekNo);
    } else {
      setWeek(1);
    }
  }, [weekNo]);

  return (
    <div className="admin_articles_root">
      <AdminNavbar />
      <div className="admin-articles-head d-flex justify-content-between align-items-center mt-24 ">
        <h3 className="ml-24">Articles</h3>
        <div className="d-flex">
          <div className="admin-dashboard-input">
            <select
              className="body2"
              value={week}
              onChange={(e) => {
                navigate(`/admin/articles?week=${e.target.value}`);
                setWeek(e.target.value);
              }}
            >
              <option value="1">Week 1</option>
              <option value="2">Week 2</option>
              <option value="3">Week 3</option>
              <option value="4">Week 4</option>
              <option value="5">Week 5</option>
              <option value="6">Week 6</option>
              <option value="7">Week 7</option>
              <option value="8">Week 8</option>
              <option value="9">Week 9</option>
              <option value="10">Week 10</option>
              <option value="11">Week 11</option>
              <option value="12">Week 12</option>
              <option value="13">Week 13</option>
              <option value="14">Week 14</option>
              <option value="15">Week 15</option>
              <option value="16">Week 16</option>
              <option value="17">Week 17</option>
              <option value="18">Week 18</option>
              <option value="19">Week 19</option>
              <option value="20">Week 20</option>
              <option value="21">Week 21</option>
              <option value="22">Week 22</option>
              <option value="23">Week 23</option>
              <option value="24">Week 24</option>
              <option value="25">Week 25</option>
              <option value="26">Week 26</option>
              <option value="27">Week 27</option>
              <option value="28">Week 28</option>
              <option value="29">Week 29</option>
              <option value="30">Week 30</option>
              <option value="31">Week 31</option>
              <option value="32">Week 32</option>
              <option value="33">Week 33</option>
              <option value="34">Week 34</option>
              <option value="35">Week 35</option>
              <option value="36">Week 36</option>
              <option value="37">Week 37</option>
              <option value="38">Week 38</option>
              <option value="39">Week 39</option>
              <option value="40">Week 40</option>
              <option value="41">Week 41</option>
              <option value="42">Week 42</option>
              <option value="43">Week 43</option>
              <option value="44">Week 44</option>
              <option value="45">Week 45</option>
              <option value="46">Week 46</option>
              <option value="47">Week 47</option>
              <option value="48">Week 48</option>
              <option value="49">Week 49</option>
              <option value="50">Week 50</option>
              <option value="51">Week 51</option>
              <option value="52">Week 52</option>
            </select>
          </div>
          <div className="admin-dashboard-input ml-24 mr-24">
            <select
              className="body2"
              value={selectedTopic}
              onChange={(e) => setSelectedTopic(e.target.value)}
            >
              <option value="">Choose Topic</option>
              {allTopics.map((at, i) => (
                <option key={i} value={at._id}>
                  {at.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="admin_articles_main p-24">
        <table className="admin_articles_table">
          <thead className="admin_articles_thead">
            <tr className="admin_articles_thead_tr">
              <th>Company</th>
              <th style={{ minWidth: 120 }}>Week</th>
              <th>Topic</th>
              <th>Article Name</th>
              <th>Article Desc</th>
              <th style={{ minWidth: 90 }}></th>
              <th ></th>
            </tr>
          </thead>
          <tbody className="admin_articles_tbody">
            {unpublishedArticles.length > 0 &&
              unpublishedArticles
                .filter((data) => data.week === parseInt(week))
                .filter((data) => {
                  if (selectedTopic === "") return data;
                  else return data.topic_id._id === selectedTopic;
                })
                .map((data, i) => (
                  <tr className="admin_articles_tbody_tr" key={i}>
                    <td className="admin_articles_tbody_companytd">
                      <div className="d-flex align-items-center">
                        <img src={data.user_id?.logo} alt="logo" />
                        <p className="body2">{data.user_id?.companyName}</p>
                      </div>
                    </td>
                    <td>
                      <p className="title2">Week {data.week}</p>
                      <p className="body2">({data.source})</p>
                    </td>
                    <td>
                      <img
                        className="table-cover"
                        src={data.cover}
                        alt={data.topic_id?.name}
                      />
                      <p className="body1">{data.topic_id?.name}</p>
                    </td>
                    <td>
                      <p className="body2">
                        <a
                          href={data?.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {data.title}
                          <span className="ml-12" style={{ color: "#4AD7D1" }}>
                            ➤
                          </span>
                        </a>
                      </p>
                    </td>
                    <td className="admin_articles_tbody_desctd">
                      <p className="caption">{data.description}</p>
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-between">
                        <button className="trans_cta ml-12">
                          <Link to={`/admin/preview/${data._id}?week=${week}`}>
                            <Visibility />
                          </Link>
                        </button>
                        <button className="trans_cta">
                          <Link to={`/admin/dashboard?article=${data._id}`}>
                            <Edit />
                          </Link>
                        </button>
                        <button
                          className="trans_cta"
                          onClick={() => handleOpen(data._id)}
                        >
                          <Delete />
                        </button>
                      </div>
                    </td>
                    <td>
                      <button
                        className="trans_cta body1"
                        onClick={() => publishArticle(data._id)}
                      >
                        Publish
                      </button>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      <ConfirmModal
        open={open}
        setOpen={setOpen}
        id={id}
        loadingfunc={getArtcles}
      />
    </div>
  );
};

export default Articles;
