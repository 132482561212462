import React from "react";
import { Delete, Edit } from "@mui/icons-material";
import pdfImage from "../../Assets/Images/pdf.png";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { Box } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  //   border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const DocCard = ({ doc, getAllDocs }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate()

  const deletePdf = async (id) => {
    await axios({
      url: `/api/v1/trendist/deletedoc/${id}`,
      method: "delete",
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Document deleted");
          getAllDocs()
        }
      })
      .catch(() => {
        toast.error("Something went wrong");
      });
  };

  return (
    <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-24">
      <div className="all_docs_card">
        <div className="all_docs_card_img">
          <img src={pdfImage} alt={doc.name} />
        </div>
        <button className="btn1" onClick={()=>navigate(`/admin/editor/${doc._id}`)}>
          <Edit />
        </button>
        <button className="btn2" onClick={handleOpen}>
          <Delete />
        </button>
        <p className="all_docs_cardp editorh2">{doc.name}</p>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            tyle={{ marginBottom: "20px" }}
            id="modal-modal-title"
            variant="h4"
            component="h2"
          >
            Are you confirm you want to delete?
          </Typography>
          <div className="d-flex mt-48 justify-content-between">
            <button
              className="zefy_CTA_04_md_fill_width_outline px-5"
              onClick={handleClose}
            >
              cancel
            </button>
            <button
              className="zefy_CTA_04_md_fill_width px-5"
              onClick={() => deletePdf(doc._id)}
            >
              Delete
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default DocCard;
