import React, { useState, useEffect, useContext, createContext } from "react";

import PropTypes from "prop-types";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const authContext = createContext();

function useProvideAuth() {
  const [admincode, setadmincode] = useState();
  const [user, setUser] = useState();
  const [isAdmin, setisAdmin] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [topicGlobal, setTopicGlobal] = useState(null)

  const [isLoading, setIsLoading] = useState(true);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [isUserAdminLoading, setIsUserAdminLoading] = useState(false);
  const [isAdminLoading, setIsAdminLoading] = useState(false);
  const [useradmincode, setUseradmincode] = useState();

  const navigate = useNavigate();

  const adminSignOut = async () => {
    await axios
      .get(`/api/v1/auth/logoutuser`)
      .then((res) => {
        if (res.data.success) {
          setadmincode(null);
          setUser(null)
          setisAdmin(false);
          setIsLoading(true);
          localStorage.clear();
          navigate("/admin/login");
          // cookies.remove('auth_tk');
        }
      })
      .catch(() => { });
  };

  async function getAdmin() {
    setIsLoading(true);
    await axios({
      method: "get",
      url: `/api/v1/auth/current/admin`,
    })
      .then((res) => {
        if (res.data.success) {
          setadmincode(res.data.admincode);
          setUser(null);
          setIsUser(false);
          setIsLoading(false);
          setisAdmin(true);
          setUseradmincode(null)
          setIsUserAdmin(false)
        }
      })
      .catch(() => {
        setisAdmin(false);
        setadmincode(null);
        setIsLoading(false);
      });
  }

  async function getUserAdmin() {
    setIsLoading(true);
    await axios({
      method: "get",
      url: `/api/v1/auth/useradmin/current`,
    })
      .then((res) => {
        if (res.data.success) {
          setUser(res.data.user);
          setUseradmincode(res.data.admincode)
          setIsUserAdmin(true)
          setadmincode(null);
          setIsUser(true);
          setIsLoading(false);
          setisAdmin(false);
        }
      })
      .catch(() => {
        setIsUserAdmin(false);
        setUseradmincode(null);
        setIsLoading(false);
      });
  }

  async function getUser() {

    setIsUserLoading(true);
    await axios({
      method: "get",
      url: `/api/v1/auth/current/user`,
    })
      .then((res) => {
        setUser(res.data.user);
        setTopicGlobal(res.data.topic)
        setadmincode(null);
        setisAdmin(false);
        setIsUserLoading(false);
        setIsUser(true);
        setUseradmincode(null)
        setIsUserAdmin(false)
      })
      .catch(() => {
        setUser(null);
        setIsUserLoading(false);
        setIsUser(false);
      });
  }

  async function logoutUser() {
    await axios
      .get(`/api/v1/auth/logoutuser`)
      .then((res) => {
        if (res.data.success) {
          setUser(null);
          setTopicGlobal(null)
          setadmincode(null);
          setisAdmin(false);
          setIsUserLoading(false);
          setIsUser(false);
        }
      })
      .catch(() => { });
  }

  useEffect(() => {
    getUser('2');
  }, []);

  useEffect(() => {
    getAdmin();
  }, [isAdminLoading]);

  useEffect(() => {
    getUserAdmin();
  }, [isUserAdminLoading]);

  return {
    admincode,
    setadmincode,
    isLoading,
    adminSignOut,
    isAdmin,
    setisAdmin,
    isUserAdmin,
    setIsUserAdmin,
    isUserAdminLoading,
    setIsUserAdminLoading,
    useradmincode,
    setUseradmincode,
    setIsLoading,
    getUserAdmin,
    getAdmin,
    user,
    setUser,
    isUser,
    setIsUser,
    getUser,
    isUserLoading,
    setIsUserLoading,
    isAdminLoading,
    setIsAdminLoading,
    topicGlobal,
    setTopicGlobal,
    logoutUser
  };
}
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
ProvideAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => useContext(authContext);
