import Axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import getofficialmails from "./Mails";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CircularProgress } from "@mui/material";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Linde = ({ company }) => {
  const [topic, settopic] = useState("");
  const [week, setweek] = useState(0);
  const [subject, setsubject] = useState("");
  const [template, settemplate] = useState();
  const [newtestmail, setnewtestmail] = useState("");
  const [testmails, settestmails] = useState([
    "rahul@vcbay.co",
    "chirag@zefyron.com",
    "nayana@vcbay.co",
    "ashutosh@vcbay.co",
    "akshada@vcbay.co",

    // "rahul.sawlani1999@gmail.com",
    // "asfakhusain99@gmail.com",
    // "aman97703@gmail.com",
    // "tech@vcbay.co",
    // "pranay@vcbay.co",
    // "asfak@vcbay.co",
    // "aman@vcbay.co",
    // "ashutosh@vcbay.co",
    // "enthuashu@gmail.com",
    // "ultimateashutosh2000@gmail.com",
    // "enthuashu@hotmail.com",
    // "2019041035@mmmut.ac.in",
    // "dev-ashutosh@outlook.com",
  ]);
  const [finalmails, setfinalmails] = useState([]);
  const [live, setlive] = useState(false);
  const [checkschedule, setcheckschedule] = useState(null);
  const [checkscheduleloader, setcheckscheduleloader] = useState(false);
  const [sendingloader, setsendingloader] = useState(false);

  const handlecheckscedule = async () => {
    try {
      setcheckscheduleloader(true);
      const response = await Axios({
        method: "post",
        url: "/api/v1/mail/checkschedule",
        data: {
          week,
          topic,
          company,
          live,
        },
      });

      if (response.data.success) {
        if (response.data.data) {
          setcheckschedule(response.data.data[0]);
        }
      } else {
        setcheckschedule(null);
      }
      setcheckscheduleloader(false);
    } catch (error) {
      setcheckscheduleloader(false);
      if (error.response && error.response.data)
        toast.error(error.response.data.error);
      else toast.error("Something went wrong");
    }
  };
  useEffect(() => {
    setsubject(
      `${
        live ? "" : "Test "
      }Trendist for ${company} | ${topic} | Week ${week} – 2025`
    );
    setfinalmails(getofficialmails(topic));
    if (topic.length > 0) handlecheckscedule();
  }, [topic, week, company, live]);
  const [upcomingSchedule, setupcomingSchedule] = useState(new Date());
  const [nexttopics, setnexttopics] = useState([]);

  const handleChange = (newValue) => {
    setupcomingSchedule(newValue);
  };

  useEffect(() => {
    if (company === "Linde") {
      let d = new Date();
      d.setDate(d.getDate() + ((4 + 7 - d.getDay()) % 7));

      d.setHours(21);

      // Then set minutes
      d.setMinutes(30);
      d.setSeconds(0);
      setupcomingSchedule(d);
      let currentDate = new Date(d);
      let startDate = new Date(currentDate.getFullYear(), 0, 1);
      let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

      let weekNumber = Math.ceil(days / 7);
      setweek(weekNumber);
      if (weekNumber % 2 === 0) {
        settopic("Geoengineering");
        setnexttopics(["Geoengineering", "Digital Healthcare & AI"]);
      } else {
        settopic("Blockchain");
        setnexttopics(["Blockchain", "Electricity Procurement"]);
      }
    } else if (company === "Wackenhut") {
      let d = new Date();
      d.setDate(d.getDate() + ((5 + 7 - d.getDay()) % 7));
      d.setHours(12);

      // Then set minutes
      d.setMinutes(30);
      d.setSeconds(0);
      setupcomingSchedule(d);
      let currentDate = new Date(d);
      let startDate = new Date(currentDate.getFullYear(), 0, 1);
      let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

      let weekNumber = Math.ceil(days / 7);
      setweek(weekNumber);

      setnexttopics(["HR News", "Innovation im Autohandel"]);
      settopic("HR News");
    } else if (company === "Wieland") {
      let d = new Date();
      d.setDate(d.getDate() + ((1 + 7 - d.getDay()) % 7));
      d.setHours(13);

      // Then set minutes
      d.setMinutes(30);
      d.setSeconds(0);
      setupcomingSchedule(d);
      let currentDate = new Date(d);
      let startDate = new Date(currentDate.getFullYear(), 0, 1);
      let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

      let weekNumber = Math.ceil(days / 7);
      setweek(weekNumber);
      if (weekNumber % 2 === 0) {
        setweek(weekNumber);
      } else {
        setweek(weekNumber + 1);
        setupcomingSchedule(d.setDate(d.getDate() + 7));
      }
      setnexttopics(["Automation in Metals Manufacturing"]);
      settopic("Automation in Metals Manufacturing");
    } else if (company === "Senat Europa") {
      let d = new Date();
      d.setDate(d.getDate() + ((4 + 7 - d.getDay()) % 7));
      d.setHours(18);

      // Then set minutes
      d.setMinutes(30);
      d.setSeconds(0);
      setupcomingSchedule(d);
      let currentDate = new Date(d);
      let startDate = new Date(currentDate.getFullYear(), 0, 1);
      let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

      let weekNumber = Math.ceil(days / 7);
      setweek(weekNumber);
      if (weekNumber % 2 !== 0) {
        setweek(weekNumber);
      } else {
        setweek(weekNumber + 1);
        setupcomingSchedule(d.setDate(d.getDate() + 7));
      }
      setnexttopics([
        "Cybersecurity",
        "Geopolitical situation (in the Energy Sector)",
      ]);
      settopic("Cybersecurity");
    } else if (company === "AMG") {
      let d = new Date();
      d.setDate(d.getDate() + ((2 + 7 - d.getDay()) % 7));
      d.setHours(14);
      // Then set minutes
      d.setMinutes(30);
      d.setSeconds(0);
      setupcomingSchedule(d);
      let currentDate = new Date(d);
      let startDate = new Date(currentDate.getFullYear(), 0, 1);
      let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
      let weekNumber = Math.ceil(days / 7);
      setweek(weekNumber);
      if (weekNumber % 2 !== 0) {
        setweek(weekNumber);
      } else {
        setweek(weekNumber + 1);
        setupcomingSchedule(d.setDate(d.getDate() + 7));
      }
      setnexttopics(["INNO Tech Monitoring"]);
      settopic("INNO Tech Monitoring");
    } else if (company === "Liebherr") {
      let d = new Date();
      d.setDate(d.getDate() + ((2 + 7 - d.getDay()) % 7));
      d.setHours(14);

      // Then set minutes
      d.setMinutes(0);
      d.setSeconds(0);
      setupcomingSchedule(d);
      let currentDate = new Date(d);
      let startDate = new Date(currentDate.getFullYear(), 0, 1);
      let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

      let weekNumber = Math.ceil(days / 7);
      setweek(weekNumber);
      setnexttopics(["Wire Arc Additive Manufacturing"]);
      settopic("Wire Arc Additive Manufacturing");
    } else if (company === "Bosch") {
      let d = new Date();
      d.setDate(d.getDate() + ((2 + 7 - d.getDay()) % 7));
      d.setHours(14);

      // Then set minutes
      d.setMinutes(0);
      d.setSeconds(0);
      setupcomingSchedule(d);
      let currentDate = new Date(d);
      let startDate = new Date(currentDate.getFullYear(), 0, 1);
      let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

      let weekNumber = Math.ceil(days / 7);
      setweek(weekNumber);
      setnexttopics(["AI Deployment Analysis"]);
      settopic("AI Deployment Analysis");
    }
  }, [company]);

  const handleTemplateUpload = (e) => {
    const file = e.target.files[0];
    if (file.size / (1024 * 1024) > 6) {
      return toast.error(`Template size should be less than 6MB`);
    }
    if (
      (company === "Wackenhut" || company === "Senat Europa") &&
      //  || company === "AMG"
      file.type !== "application/pdf"
    ) {
      return toast.error(`Upload PDF files for ${company}`);
    } else if (
      company !== "Wackenhut" &&
      company !== "Senat Europa" &&
      company !== "AMG" &&
      company !== "Bosch" &&
      file.type !== "text/html"
    ) {
      return toast.error("Upload HTML files");
    }
    settemplate(file);
  };
  const handlesendmails = async () => {
    try {
      setsendingloader(true);
      if (topic.length === 0) return toast.error("Choose Topic");
      if (week === 0) return toast.error("Mention Week");
      if (subject.trim().length === 0) return toast.error("Mention Week");
      if (!template) return toast.error("Attach template");

      const formdata = new FormData();
      formdata.append("template", template);
      formdata.append("subject", subject); //append the values with key, value pair
      formdata.append("week", week);
      formdata.append("topic", topic);
      let emails = live ? finalmails : testmails;
      formdata.append("emails", JSON.stringify(emails));
      formdata.append("live", live);
      formdata.append("company", company);
      formdata.append("schedule", upcomingSchedule);

      const response = await Axios({
        method: "post",
        url: "/api/v1/mail/schedule",
        data: formdata,
      });
      if (response.data.success) {
        setsendingloader(false);
        toast.success("Mails scheduled Successfully");
        handleClose();
      }
    } catch (error) {
      setsendingloader(false);
      if (error.response && error.response.data)
        toast.error(error.response.data.error);
      else toast.error("Something went wrong");
    }
  };
  const handlesendInstantmails = async () => {
    try {
      setsendingloader(true);
      if (
        checkschedule &&
        !window.confirm(
          "You already scheduled this email, Still want to procede?"
        )
      ) {
        return;
      }
      if (topic.length === 0) return toast.error("Choose Topic");
      if (week === 0) return toast.error("Mention Week");
      if (subject.trim().length === 0) return toast.error("Mention Week");
      if (!template) return toast.error("Attach template");

      const formdata = new FormData();
      formdata.append("template", template);
      formdata.append("subject", subject); //append the values with key, value pair
      formdata.append("week", week);
      formdata.append("topic", topic);
      let emails = live ? finalmails : testmails;
      formdata.append("emails", JSON.stringify(emails));
      formdata.append("live", live);
      formdata.append("company", company);

      const response = await Axios({
        method: "post",
        url: "/api/v1/mail/email",
        data: formdata,
      });
      if (response.data.success) {
        setsendingloader(false);
        toast.success("Mails sent Successfully");
        handleClose();
      }
    } catch (error) {
      setsendingloader(false);
      if (error.response && error.response.data)
        toast.error(error.response.data.error);
      else toast.error("Something went wrong");
    }
  };
  const handleRemoveItem = (id) => {
    settestmails((mails) => mails.filter((_, index) => index !== id));
  };
  const handleRemoveItem2 = (id) => {
    setfinalmails((mails) => mails.filter((_, index) => index !== id));
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    if (topic.length === 0) return toast.error("Choose Topic");
    if (week === 0) return toast.error("Mention Week");
    if (subject.trim().length === 0) return toast.error("Mention Week");
    if (!template) return toast.error("Attach template");
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  return (
    <div className="trendistmailform">
      <p style={{ fontWeight: "bolder" }}>
        Next Schedule : {new Date(upcomingSchedule).toString()}
      </p>
      <p style={{ fontWeight: "bolder" }}>Next Topics :</p>
      {nexttopics &&
        nexttopics.map((v, i) => {
          return (
            <>
              <ul>
                <li>{v}</li>
              </ul>
            </>
          );
        })}
      <hr />
      <br />
      <form>
        <div class="mb-3 ">
          <label for="exampleInputPassword1" class="form-label">
            Select Topic
          </label>
          <select
            onChange={(e) => settopic(e.target.value)}
            value={topic}
            class="form-select"
            aria-label="Default select example"
          >
            <option value="">Choose Topic</option>
            {nexttopics.map((v, i) => {
              return (
                <>
                  <option key={i} value={v}>
                    {v}
                  </option>
                </>
              );
            })}
          </select>
        </div>
        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">
            Week number
          </label>
          <input
            onChange={(e) => setweek(e.target.value)}
            value={week}
            type="number"
            class="form-control"
            id="exampleInputPassword1"
          />
        </div>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">
            Email Subject
          </label>
          <input
            onChange={(e) => setsubject(e.target.value)}
            value={subject}
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
          />
        </div>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">
            Email Template
          </label>
          <input
            onChange={handleTemplateUpload}
            type="file"
            accept={
              company === "Wackenhut" || company === "Senat Europa"
                ? // || company === "AMG"
                  "application/pdf"
                : "text/html"
            }
            class="form-control"
            id="exampleFormControlInput1"
          />
        </div>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={live}
                onChange={(e) => setlive(e.target.checked)}
              />
            }
            // label={live ? "Live" : "Testing"}
            label={
              live ? (
                <span style={{ color: "green", fontSize: "20px" }}>Live</span>
              ) : (
                <span style={{ color: "red", fontSize: "20px" }}>Testing</span>
              )
            }
          />
        </FormGroup>
        <br />
        {live ? (
          <>
            {finalmails &&
              finalmails.map((v, i) => {
                return (
                  <>
                    <ul role="toolbar">
                      <li>
                        {v}
                        <button
                          onClick={() => handleRemoveItem2(i)}
                          style={{ backgroundColor: "red", color: "white" }}
                          type="button"
                        >
                          X
                        </button>
                      </li>
                    </ul>
                  </>
                );
              })}
            <div className="mb-3">
              <input
                onChange={(e) => setnewtestmail(e.target.value)}
                value={newtestmail}
                placeholder="Add Live mails"
                type="text"
                class="form-control"
              />
              <button
                style={{ backgroundColor: "green", color: "white" }}
                onClick={() => {
                  if (newtestmail.trim().length !== 0)
                    setfinalmails((v) => [...v, newtestmail]);
                  setnewtestmail("");
                }}
                className="p-2"
                type="button"
              >
                Add
              </button>
            </div>
          </>
        ) : (
          <>
            {testmails.map((v, i) => {
              return (
                <>
                  <ul role="toolbar">
                    <li>
                      {v}
                      <button
                        onClick={() => handleRemoveItem(i)}
                        style={{ backgroundColor: "red", color: "white" }}
                        type="button"
                      >
                        X
                      </button>
                    </li>
                  </ul>
                </>
              );
            })}
            <div className="mb-3">
              <input
                onChange={(e) => setnewtestmail(e.target.value)}
                value={newtestmail}
                placeholder="Add Test mails"
                type="text"
                class="form-control"
              />
              <br />
              <button
                className="p-2"
                style={{ backgroundColor: "green", color: "white" }}
                onClick={() => {
                  if (newtestmail.trim().length !== 0)
                    settestmails((v) => [...v, newtestmail]);
                  setnewtestmail("");
                }}
                type="button"
              >
                Add
              </button>
            </div>
          </>
        )}
        <br />
        {checkscheduleloader ? (
          <p>
            Checking schedule...
            <CircularProgress />{" "}
          </p>
        ) : null}
        {checkschedule ? (
          <p style={{ color: "red" }}>
            You have already scheduled this email which will be sent at
            {` ${new Date(checkschedule.nextRunAt).toString()}`}
          </p>
        ) : (
          <></>
        )}
        <div className="mb-3 mt-3">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
              <DateTimePicker
                label="Date&Time picker"
                value={upcomingSchedule}
                onChange={handleChange}
                renderInput={(params) => (
                  <TextField
                    onKeyDown={(e) => e.preventDefault()}
                    {...params}
                  />
                )}
              />
            </Stack>
          </LocalizationProvider>
        </div>
        <div className="mb-3 mt-3">
          <button
            onClick={() => handleOpen()}
            type="button"
            class="btn btn-primary"
          >
            {`Schedule ${live ? "Live" : "Testing"} mails`}
          </button>
        </div>
      </form>
      <Modal
        open={open}
        onClose={handleClose}
        className="trendistmailform"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Topic : {topic}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Subject : {subject}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Week : {week}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Template : {template?.name}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Schedule : {new Date(upcomingSchedule).toString()}
          </Typography>
          <p>Recipient Mails :</p>
          {live ? (
            <>
              {finalmails &&
                finalmails.map((v, i) => {
                  return (
                    <>
                      <ul role="toolbar">
                        <li>{v}</li>
                      </ul>
                    </>
                  );
                })}
            </>
          ) : (
            <>
              {testmails.map((v, i) => {
                return (
                  <>
                    <ul role="toolbar">
                      <li>{v}</li>
                    </ul>
                  </>
                );
              })}
            </>
          )}
          <div className="row">
            <div className="col-10">
              {" "}
              <div className="mb-3">
                <button
                  onClick={() => handlesendmails()}
                  type="button"
                  class="btn btn-primary"
                >
                  {`Final Schedule ${live ? "Live" : "Testing"} mails`}
                </button>
              </div>
              <div className="mb-3">
                <button
                  onClick={() => handlesendInstantmails()}
                  type="button"
                  class="btn btn-danger"
                >
                  {`Send Instant ${live ? "Live" : "Testing"} mails`}
                </button>
              </div>
            </div>
            <div className="col-2">
              {" "}
              {sendingloader ? (
                <div className="mb-3">
                  <CircularProgress />{" "}
                </div>
              ) : null}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Linde;
