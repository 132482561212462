import React, { useEffect, useState } from 'react'

import './index.css'
import { toast } from 'react-toastify'
import axios from 'axios'
import { Box, CircularProgress, Modal } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CancelIcon from '@mui/icons-material/Cancel';
const getCurrentDate = date => {
  let today
  if (date) {
    today = new Date(date)
  } else {
    today = new Date()
  }
  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, '0')
  const day = String(today.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 'none',
  outline: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius: 3
}
const FundingNews = () => {
  const navigate = useNavigate()
  const [fundingDate, setFundingDate] = useState(getCurrentDate())
  const [heading, setHeading] = useState('')
  const [news, setNews] = useState('')
  const [aboutCompany, setAboutCompany] = useState('')
  const [finPurpose, setFinPurpose] = useState('')
  const [companyRemark, setCompanyRemark] = useState('')
  const [investorRemark, setInvestorRemark] = useState('')
  const [conclusion, setConclusion] = useState('')
  const [uploading, setUploading] = useState(false)
  const [coverImage, setCoverImage] = useState('')
  const [metaTitle, setMetaTitle] = useState('')
  const [metaDes, setMetaDes] = useState('')
  const [keyWord, setKeyWord] = useState('')
  const [keyWords, setKeywords] = useState([])
  const [conclusionHeading, setConclusionHeading] = useState('Conclusion')
  const [heading1, setHeading1] = useState('About the company')
  const [heading2, setHeading2] = useState('Purpose of financing')
  const [heading3, setHeading3] = useState(
    'What the company’s official has to add'
  )
  const [heading4, setHeading4] = useState('What the investors have to comment')
  const [link,setLink] = useState('')
  const handleFundingDate = e => {
    setFundingDate(e.target.value)
  }
  const handleFileChange = event => {
    const file = event.target.files[0]
    if (
      file &&
      (file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg')
    ) {
      const formdata = new FormData()
      formdata.append('blogcover', file)
      setUploading(true)
      axios({
        method: 'post',
        url: '/api/v1/blog/uploadblogimage',
        data: formdata
      })
        .then(res => {
          const { data } = res.data
          setCoverImage(data)
          setUploading(false)
          toast.success('Profile Image upload', { position: 'bottom-right' })
        })
        .catch(() => {
          setUploading(false)
          toast.error('Error in upload', { position: 'bottom-right' })
        })
    } else {
      toast.error('Only PNG, JPG, JPEG allowed')
    }
  }

  const saveData = async () => {
    await axios({
      url: `https://backend.zefyron.com/api/blog/publish/trendist/cc76c734-8954-4350-a2fe-8b3b88c3c48c`,
      method: 'post',
      data: {
        Category: 'Finance',
        isFundingNews: true,
        fundingNewsData: {
          title: heading,
          date: fundingDate,
          coverImg: coverImage,
          news: news,
          heading1Ans: aboutCompany,
          heading2Ans: finPurpose,
          heading3Ans: companyRemark,
          heading4Ans: investorRemark,
          conclusion: conclusion,
          seoTitle: metaTitle,
          seoDesc: metaDes,
          seoKeywords: keyWords,
          heading1,
          heading2,
          heading3,
          heading4,
          conclusionHeading,
          link
        }
      }
    })
      .then(res => {
        const { data } = res.data
        console.log(data)
        toast.success('saved')
        navigate('/admin/all/blogs')
      })
      .catch(err => {
        console.log(err)
        toast.error('Error Occured')
      })
  }

  const [editedData, setEditedData] = useState(null)

  const fetchData = async id => {
    await axios({
      url: `https://backend.zefyron.com/api/blog/fn/trendist/cc76c734-8954-4350-a2fe-8b3b88c3c48c/${id}`,
      method: 'get'
    })
      .then(res => {
        const { data } = res.data
        setEditedData(data[0])
      })
      .catch(err => {
        console.log(err)
        toast.error('Error Occured')
      })
  }
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  useEffect(() => {
    if (id) {
      fetchData(id)
    }
  }, [id])

  useEffect(() => {
    if (editedData) {
      console.log(editedData?.fundingNewsData?.seoKeywords)
      setFundingDate(getCurrentDate(editedData?.fundingNewsData?.date))
      setHeading(editedData?.fundingNewsData?.title)
      setHeading1(editedData?.fundingNewsData?.heading1)
      setHeading2(editedData?.fundingNewsData?.heading2)
      setHeading3(editedData?.fundingNewsData?.heading3)
      setHeading4(editedData?.fundingNewsData?.heading4)
      setHeading4(editedData?.fundingNewsData?.heading4)
      setNews(editedData?.fundingNewsData?.news)
      setAboutCompany(editedData?.fundingNewsData?.heading1Ans)
      setFinPurpose(editedData?.fundingNewsData?.heading2Ans)
      setCompanyRemark(editedData?.fundingNewsData?.heading3Ans)
      setInvestorRemark(editedData?.fundingNewsData?.heading4Ans)
      setConclusion(editedData?.fundingNewsData?.conclusion)
      setMetaTitle(editedData?.fundingNewsData?.seoTitle)
      setMetaDes(editedData?.fundingNewsData?.seoDesc)
      setKeywords(editedData?.fundingNewsData?.seoKeywords)
      setCoverImage(editedData?.fundingNewsData?.coverImg)
      setConclusionHeading(editedData?.fundingNewsData?.conclusionHeading)
      setLink(editedData?.fundingNewsData?.link)
      
    }
  }, [editedData])

  const updateDate = async () => {
    await axios({
      url: `https://backend.zefyron.com/api/blog/fn-update/trendist/cc76c734-8954-4350-a2fe-8b3b88c3c48c/${id}`,
      method: 'post',
      data: {
        fundingNewsData: {
          title: heading,
          date: fundingDate,
          coverImg: coverImage,
          news: news,
          heading1Ans: aboutCompany,
          heading2Ans: finPurpose,
          heading3Ans: companyRemark,
          heading4Ans: investorRemark,
          conclusion: conclusion,
          seoTitle: metaTitle,
          seoDesc: metaDes,
          seoKeywords: keyWords,
          heading1,
          heading2,
          heading3,
          heading4,
          conclusionHeading,
          link
        }
      }
    })
      .then(res => {
        const { success } = res.data
        if (success) {
          toast.success('Funding News Updated')
          navigate('/admin/all/blogs')
        }
      })
      .catch(err => {
        console.log(err)
        toast.error('Error Occured')
      })
  }

  const [openInfo, setOpenInfo] = useState(false)
  const handleOpenInfo = () => setOpenInfo(true)
  const handleCloseInfo = () => setOpenInfo(false)
  return (
    <div className='container-news'>
      <div className='funding-editor-header'>
        <h5 className='mt-20'>Create Funding News</h5>
        <div>
          <button
            className='zefy_CTA_01_small_bg_black'
            onClick={() => {
              if (id) {
                updateDate()
              } else {
                saveData()
              }
            }}
          >
            {id ? 'Update' : 'Save'}
          </button>
          <button
            className='zefy_CTA_01_small_bg_black ml-8'
            onClick={() => handleOpenInfo()}
          >
            <InfoIcon />
          </button>
        </div>
      </div>

      <div className='row mt-40'>
        <div className='col-3'>
          <p className='form_label'>Publish Date</p>
          <input
            type='date'
            value={fundingDate}
            onChange={handleFundingDate}
            className='input-fiels'
          />
        </div>
        <div className='col-9'>
          <p className='form_label'>Title</p>
          <input
            type='text'
            value={heading}
            onChange={e => setHeading(e.target.value)}
            className='input-fiels'
            placeholder='Boulder, Colorado, United States: Travertine Raises $8.5M in Financing to Revolutionize Carbon Capture'
          />
        </div>
      </div>

      {coverImage ? (
        <img src={coverImage} alt='Cover' className='coverImage' />
      ) : (
        <label className='mt-20 cover-upload' htmlFor='cover-upload'>
          {uploading ? (
            <p>
              {' '}
              <CircularProgress style={{ color: '#000' }} />
            </p>
          ) : (
            <p>Upload Cover Image</p>
          )}

          <input
            type='file'
            accept='image/*'
            onChange={handleFileChange}
            name='cover-upload'
            id='cover-upload'
            hidden={true}
          />
        </label>
      )} 
      <input
        type='text'
        value={link}
        onChange={e => setLink(e.target.value)}
        className='input-fiels mt-2'
        placeholder='Website link'
        
      />

      <div className='mt-20'>
        <p className='form_label'>News</p>
        <textarea
          type='text'
          value={news}
          onChange={e => setNews(e.target.value)}
          className='input-fiels no-resize'
          placeholder='Travertine, a chemical and environmental consulting startup from Boulder, Colorado, has successfully raised $8.5M in its latest funding.....'
          rows={3}
        />
      </div>

      <div className='mt-20'>
        <input
          className='form_lable-input'
          value={heading1}
          onChange={e => setHeading1(e.target.value)}
        />
        {/* <p className='form_label'>About the company</p> */}
        <textarea
          type='text'
          value={aboutCompany}
          onChange={e => setAboutCompany(e.target.value)}
          className='input-fiels no-resize'
          placeholder='Travertine, founded in January 2022, focuses on a process that precipitates carbonate minerals....'
          rows={3}
        />
      </div>

      <div className='mt-20'>
        {/* <p className='form_label'>Purpose of financing</p> */}
        <input
          className='form_lable-input'
          value={heading2}
          onChange={e => setHeading2(e.target.value)}
        />
        <textarea
          type='text'
          value={finPurpose}
          onChange={e => setFinPurpose(e.target.value)}
          className='input-fiels no-resize'
          placeholder='With the new funding, Travertine plans to scale its operations and further develop its technology. The ....'
          rows={3}
        />
      </div>

      <div className='mt-20'>
        {/* <p className='form_label'>What the company’s official has to add</p> */}
        <input
          className='form_lable-input'
          value={heading3}
          onChange={e => setHeading3(e.target.value)}
        />
        <textarea
          type='text'
          value={companyRemark}
          onChange={e => setCompanyRemark(e.target.value)}
          className='input-fiels no-resize'
          placeholder='In a statement about the funding round, Travertine expressed their gratitude to the investors and ....'
          rows={3}
        />
      </div>

      <div className='mt-20'>
        {/* <p className='form_label'>What the investors have to comment</p> */}
        <input
          className='form_lable-input'
          value={heading4}
          onChange={e => setHeading4(e.target.value)}
        />
        <textarea
          type='text'
          value={investorRemark}
          onChange={e => setInvestorRemark(e.target.value)}
          className='input-fiels no-resize'
          placeholder='Breakthrough Energy Ventures, the lead investor, emphasized the potential of Travertine ....'
          rows={3}
        />
      </div>

      <div className='mt-20'>
        <input
          className='form_lable-input'
          value={conclusionHeading}
          onChange={e => setConclusionHeading(e.target.value)}
        />
        <textarea
          type='text'
          value={conclusion}
          onChange={e => setConclusion(e.target.value)}
          className='input-fiels no-resize'
          placeholder='Travertine, a chemical and environmental consulting startup from Boulder, Colorado, has successfully raised $8.5M in its latest funding.....'
          rows={3}
        />
      </div>

      <h5 className='mt-40'>SEO</h5>

      <div className='row mt-20'>
        <div className='col-6'>
          <p className='form_label'>Meta title</p>
          <input
            type='text'
            value={metaTitle}
            onChange={e => setMetaTitle(e.target.value)}
            className='input-fiels'
            placeholder='Sample title'
          />
          <div className='d-flex align-items-center'>
            <p className='font_14'>Min : {metaTitle?.length}/30 characters</p>
            <p className='font_14 ml-12'>
              Max : {metaTitle?.length}/60 characters
            </p>
          </div>
        </div>
        <div className='col-6'>
          <p className='form_label'>Meta description</p>
          <input
            type='text'
            value={metaDes}
            onChange={e => setMetaDes(e.target.value)}
            className='input-fiels'
            placeholder='Sample meta description'
          />
          <div className='d-flex align-items-center'>
            <p className='font_14'>Min : {metaDes?.length}/50 characters</p>
            <p className='font_14 ml-12'>
              Max : {metaDes?.length}/160 characters
            </p>
          </div>
        </div>
      </div>
      <div className='mt-20 '>
        <p className='form_label'>Keywords</p>
        <div className='position-relative'>
          <input
            type='text'
            value={keyWord}
            onChange={e => setKeyWord(e.target.value)}
            className='input-fiels'
            placeholder='Add keyword'
            onKeyPress={event => {
              if (event.key === 'Enter') {
                setKeywords(prev => [...prev, keyWord])
                setKeyWord('')
              }
            }}
          />
          <button
            onClick={e => {
              if (keyWord === '') {
                return
              }
              setKeywords(prev => [...prev, keyWord])
              setKeyWord('')
            }}
            className='keyword-cta'
          >
            Add
          </button>
        </div>
        <div className='d-flex align-items-center gap-20'>
          {keyWords.map(key => {
            return <p className='keyword'>#{key} <span className='ml-6 pointer' style={{color:'#A60E0E'}} onClick={(e)=>{
              e.stopPropagation();
              e.preventDefault();
              setKeywords(keyWords.filter(item=>item !== key))
            }}><CancelIcon color='red'/></span></p>
          })}
        </div>
      </div>
      <br />
      <br />
      <br />

      <Modal
        open={openInfo}
        onClose={handleCloseInfo}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div>
            <p className='info-header'>Bold Text</p>
            <p className='info-text ml-8'>
              To make text bold, wrap it with asterisk (*). For example -{' '}
            </p>
            <p className='info-example ml-8'>*This text will be bold*</p>
          </div>
          <div className='mt-24'>
            <p className='info-header'>Links</p>
            <p className='info-text ml-8'>
              To create a hyperlink, use square brackets for the link text
              followed by parentheses for the URL. For example:
            </p>
            <p className='info-example ml-8'>[Link](http://example.com)</p>
            <p className='info-text mt-8 ml-8'>
              If you want to specify a different color for the hyperlink text,
              use a curly bracket with the color code after the URL. For
              example:
            </p>
            <p className='info-example ml-8'>
              {String('[link](http://www.example.com){#00BFD7}')}
            </p>
          </div>
          <div className='mt-24'>
            <p className='info-header'>Underline</p>
            <p className='info-text ml-8'>
              To make text underlined, wrap it with dash (-). Make Sure that, there is no space. For example - 
            </p>
            <p className='info-example ml-8'>-underline-</p>
  
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default FundingNews
