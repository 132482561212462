import * as React from "react";
import Box from "@mui/material/Box";
// import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Axios from "axios";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  //   border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function index({open, setOpen, id, loadingfunc}) {
  //   const handleOpen = () => setOpen(true);\
  const handleClose = () => setOpen(false);
  const deleteArticle = async (id) => {
    await Axios({
        url: `/api/v1/article/delete/${id}`,
        method: "delete"
    }).then((res) => {
        if (res.data.success) {
            toast.success("Article Deleted")
            loadingfunc()
            handleClose()
        }
    }).catch(() => toast.error("Error Occured"))
}
  

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            tyle={{ marginBottom: "20px" }}
            id="modal-modal-title"
            variant="h4"
            component="h2"
          >
            Are you confirm you want to delete?
          </Typography>
          <div className="d-flex mt-48 justify-content-between">
            <button className="zefy_CTA_04_md_fill_width_outline px-5" onClick={handleClose}>
              cancel
            </button>
            <button
              className="zefy_CTA_04_md_fill_width px-5"
              onClick={()=>deleteArticle(id)}
            >
              Delete
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
