import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AdminNavbar from "../../../Components/Navbar/AdminNavbar";
import { useAuth } from "../../../Utils/Auth";
import useQuery from "../../../Utils/querry";
import ArticleForm from "./ArticleForm";
import AskInfo from "./AskInfo";

const Dashboard = () => {
  const [step, setStep] = useState(1);
  const [companyName, setCompanyName] = useState("");
  const [user_id, setUser_id] = useState(null);
  const [topic, setTopic] = useState("");
  const [topic_id, setTopic_id] = useState(null);
  const [week, setWeek] = useState("");
  const [date, setDate] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [heading, setHeading] = useState("");
  const [url, setUrl] = useState("");
  const [description, setDescription] = useState("");
  const [source, setSource] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const navigate = useNavigate();
  const { isAdmin } = useAuth();
  const query = useQuery();
  const article = query.get("article");

  const createArticle = async () => {
    if (!topic || !heading || !description || !url || !week) {
      return toast.error("Please fill all the fields");
    }
    await Axios({
      url: "/api/v1/article/create",
      method: "post",
      data: {
        topic_id: topic,
        title: heading,
        cover: coverImage,
        description: description,
        link: url,
        week: Number(week),
        user_id: companyName,
        date: date,
        source: source,
      },
    }).then((res) => {
      if (res.data.success) {
        setStep(1);
        toast.success("Article created!");
      }
    });
  };

  const updateArticle = async () => {
    if (!topic || !heading || !description || !url || !week) {
      return toast.error("Please fill all the fields");
    }
    await Axios({
      url: `/api/v1/article/update/${article}`,
      method: "put",
      data: {
        topic_id: topic,
        title: heading,
        cover: coverImage,
        description: description,
        link: url,
        week: Number(week),
        user_id: companyName,
        date: date,
        source: source,
      },
    }).then((res) => {
      if (res.data.success) {
        setStep(1);
        toast.success("Article updated");
        // navigate(-1)
        setUser_id(null);
        setTopic_id(null);
      }
    });
  };

  const getArticle = async (id) => {
    await Axios({
      url: `/api/v1/article/${id}`,
      method: "get",
    })
      .then((res) => {
        const { data } = res.data;
        setIsUpdate(true);
        setUser_id(data.user_id);
        setCompanyName(data.user_id._id);
        setTopic_id(data.topic_id);
        setWeek(data.week);
        setDate(data.date);
        setTopic(data.topic_id._id);
        setCoverImage(data.cover);
        setHeading(data.title);
        setSource(data.source);
        setUrl(data.link);
        setDescription(data.description);
      })
      .catch((err) => {
        toast.error("Error Occured");
      });
  };

  useEffect(() => {
    if (article) {
      getArticle(article);
    }
  }, [article]);

  useEffect(() => {
    if (!isAdmin) {
      navigate(`/admin/login`);
    }
  }, [isAdmin]);

  return (
    <div>
      <AdminNavbar />
      <div className="text-end p-24">
        <button className="zefy_CTA_01_small_bg_black">
          <Link to={"/admin/add/topic"}>Create Topic</Link>
        </button>
      </div>
      {step === 1 ? (
        <AskInfo
          step={step}
          setStep={setStep}
          companyName={companyName}
          setCompanyName={setCompanyName}
          topic={topic}
          setTopic={setTopic}
          week={week}
          setWeek={setWeek}
          date={date}
          setDate={setDate}
          isUpdate={isUpdate}
          user_id={user_id}
          topic_id={topic_id}
        />
      ) : (
        <ArticleForm
          setStep={setStep}
          coverImage={coverImage}
          setCoverImage={setCoverImage}
          heading={heading}
          setHeading={setHeading}
          url={url}
          setUrl={setUrl}
          isUpdate={isUpdate}
          description={description}
          setDescription={setDescription}
          createArticle={createArticle}
          updateArticle={updateArticle}
          source={source}
          setSource={setSource}
        />
      )}
    </div>
  );
};

export default Dashboard;
