import React, { useState, useRef, useEffect } from "react";
import "./login.css";
import { toast } from "react-toastify";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { useAuth } from "../../../Utils/Auth";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);

  const [calltimer, setcalltimer] = useState(false);
  const [code, setCode] = useState(null);
  const [otpStatus, setotpStatus] = useState(null);

  const { setIsAdminLoading, isAdmin, isAdminLoading } = useAuth();

  const navigate = useNavigate();

  const sendOtp = async () => {
    if (calltimer) {
      toast.error("Please wait for sometime before requesting another OTP", {
        position: "bottom-right",
      });
    } else {
      Axios({
        method: "GET",
        url: `/api/v1/auth/request`,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }).then((response) => {
        if (response.data.success) {
          toast.success("OTP Sent Successfully", { position: "bottom-right" });
          setotpStatus("sent");
          setcalltimer(true);
        } else {
          toast.error("Retry after some time", {
            position: "bottom-right",
          });
        }
      });
    }
  };

  const verifyOtp = async () => {
    if (!code || code.length < 4) {
      toast.error("Please Enter Valid OTP", { position: "bottom-right" });
    } else {
      Axios({
        method: "POST",
        url: `/api/v1/auth/verify`,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: { code, email, password },
      })
        .then((response) => {
          if (response.data.status === "approved") {
            toast.success("OTP Verified Successfully", {
              position: "bottom-right",
            });
            setIsAdminLoading(!isAdminLoading);

            navigate("/admin-check");

            setotpStatus("verified");
            setCode(null);
          } else if (response.data.status === "pending") {
            toast.error("Invalid OTP", { position: "bottom-right" });
            setotpStatus("invalid");
            setCode(null);
          } else {
            toast.error(response.message, { position: "bottom-right" });
          }
        })
        .catch((error) => {
          if (error.response.status === 401)
            toast.error("Invalid Credentials!");
        });
    }
  };

  const RenderTime = ({ remainingTime }) => {
    const currentTime = useRef(remainingTime);
    const prevTime = useRef(null);
    const isNewTimeFirstTick = useRef(false);
    const [, setOneLastRerender] = useState(0);

    if (currentTime.current !== remainingTime) {
      isNewTimeFirstTick.current = true;
      prevTime.current = currentTime.current;
      currentTime.current = remainingTime;
    } else {
      isNewTimeFirstTick.current = false;
    }

    // force one last re-render when the time is over to tirgger the last animation
    if (remainingTime === 0) {
      setTimeout(() => {
        setOneLastRerender((val) => val + 1);
      }, 20);
      setcalltimer(false);
    }

    const isTimeUp = isNewTimeFirstTick.current;
    return (
      <div className="time-wrapper">
        <div key={remainingTime} className={`time ${isTimeUp ? "up" : ""}`}>
          <p>{remainingTime}</p>
        </div>
      </div>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email,
      password,
    };
    await Axios.post("/api/v1/auth/login", data)
      .then((res) => {
        if (res.status === 200) sendOtp();
      })
      .catch((error) => {
        if (error.response.status === 401) toast.error("Invalid Credentials!");
      });
  };

  useEffect(() => {
    if (isAdmin) {
      navigate(`/admin/dashboard`);
    }
  }, [isAdmin]);

  return (
    <div className="twf_login_root">
      <div className="twf_login_main">
        <h2>Admin Login</h2>
        <div className="twf_login_inputs mt-40">
          <div className="twf_login_input mb-10">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="body1"
              placeholder="Enter your email"
            />
          </div>
          <div className="twf_login_input">
            <input
              type={visible ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="body1"
              placeholder="Enter your password"
            />
            <button
              className="trans_cta eye_btn point"
              onClick={() => setVisible(!visible)}
            >
              {visible ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-eye-slash-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.79 12.912l-1.614-1.615a3.5 3.5 0 01-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 002.79-.588zM5.21 3.088A7.028 7.028 0 018 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 00-4.474-4.474L5.21 3.089z"></path>
                  <path d="M5.525 7.646a2.5 2.5 0 002.829 2.829l-2.83-2.829zm4.95.708l-2.829-2.83a2.5 2.5 0 012.829 2.829zm3.171 6l-12-12 .708-.708 12 12-.708.708z"></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-eye-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.5 8a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path>
                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"></path>
                </svg>
              )}
            </button>
          </div>
          <div>
            <div className="mt-16">
              <>
                {otpStatus === "sent" ? (
                  <>
                    <div className="twf_login_input">
                      <p style={{ color: "#000" }} className="caption">
                        OTP sent to admin phone number
                      </p>
                      <input
                        type="number"
                        className="body1 w_100 mt-10"
                        placeholder="OTP"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                      />{" "}
                      <div className="d-flex align-items-center mt-6">
                        <p className="caption color_dark_grey">
                          Didn&apos;t get the OTP?{" "}
                        </p>
                        <p className="body2 point" onClick={() => sendOtp()}>
                          Resend
                        </p>
                      </div>
                      <button
                        type="button"
                        onClick={verifyOtp}
                        className="w_25 zefy_CTA_01_small_bg_white mt-16"
                      >
                        Verify OTP
                      </button>
                    </div>
                  </>
                ) : null}
              </>
              &nbsp; &nbsp;
              {calltimer ? (
                <div className="timer-wrapper" style={{ display: "none" }}>
                  <CountdownCircleTimer
                    isPlaying
                    duration={60}
                    size={50}
                    strokeWidth={5}
                    colors={[["#870015", 0.33], ["#222", 0.33], ["#123769"]]}
                  >
                    {RenderTime}
                  </CountdownCircleTimer>
                </div>
              ) : null}
            </div>

            {otpStatus === "verified" ? (
              <p className="p_small_white">OTP Verified Successfully</p>
            ) : null}

            {otpStatus === "invalid" ? (
              <>
                <div className="row no-margin align-items-center">
                  <p className="p_small_white text-left">Invalid OTP</p>
                  <input
                    type="number"
                    className="auth_input w_49"
                    placeholder="OTP"
                    onChange={(e) => setCode(e.target.value)}
                  />{" "}
                  &nbsp; &nbsp;
                  <p className="width_auto p_small_whiteGray no-margin">
                    Didn&apos;t get the OTP?{" "}
                  </p>
                  <p
                    className="width_auto p_small_white no-margin p_button no-padding"
                    onClick={() => sendOtp()}
                  >
                    Resend
                  </p>
                  <button
                    type="button"
                    onClick={verifyOtp}
                    className="w_25 zefy_CTA_01_small_bg_white"
                  >
                    Verify OTP
                  </button>
                </div>
                <br />
              </>
            ) : null}
            <br />
          </div>
          {!otpStatus ? (
            <div className="loginbtns">
              <div className="twf_login_submit mt-24">
                <button
                  className="zefy_CTA_01_small_bg_white w_100 point"
                  onClick={handleSubmit}
                >
                  Login
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Login;
