import { nanoid } from "nanoid";
import React, { useState } from "react";
import { useEffect } from "react";
import InputElement from "./InputElement";
// import Outputpage from "./Outputpage/Practice";
// import Render from "./Outputpage/Render";
import Outputpage from "./Outputpage";
import "./index.css";
import ReactToPrint from "react-to-print";
import { useAuth } from "../../Utils/Auth";
import Axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
const ref = React.createRef();

const Editor = () => {
  const { isAdmin } = useAuth();
  const [mainjson, setmainjson] = useState([]);
  const [topic, settopic] = useState("");
  const [company, setcompany] = useState("Wackenhut");
  const [week, setweek] = useState(0);
  const [currentid, setcurrentid] = useState();
  const [elementType, setelementType] = useState("");
  const [content, setcontent] = useState("");
  const [image, setimage] = useState();
  const [bookmarkId, setBookmarkId] = useState("");
  const [pageBreak, setPageBreak] = useState(false);
  const [paraimage, setParaimage] = useState({
    url: "",
    size: 100,
    align: "left",
  });
  const [docName, setDocName] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [coverdate, setCoverDate] = useState(null);
  const [listcontents, setlistcontents] = useState([]);
  const [newbullet, setnewbullet] = useState("");
  const [isUploading, setisUploading] = useState(false);
  const navigate = useNavigate()

  const { id } = useParams();
  useEffect(() => {
    if (!isAdmin) {
      navigate(`/admin/login`);
    }
  }, [isAdmin]);
  const handlePicUpload = (e) => {
    const image = e.target.files[0];
    // removable code
    // setimage(URL.createObjectURL(e.target.files[0]));
    setisUploading(true);

    const formdata = new FormData();
    formdata.append("templateImages", image);
    Axios({
      method: "post",
      url: "/api/v1/automate/addimage",
      data: formdata,
    })
      .then((res) => {
        const { Location } = res.data;
        setimage(Location);
        // setParaimage({
        //   ...paraimage,
        //   url: Location,
        // });
        setisUploading(false);
      })
      .catch((error) => {
        setisUploading(false);
        if (error.response && error.response.data) {
          return toast.error(error.response.data.error);
        }
      });
  };

  const handleImageUpload = (e) => {
    const image = e.target.files[0];
    // removable code
    // setimage(URL.createObjectURL(e.target.files[0]));
    setisUploading(true);

    const formdata = new FormData();
    formdata.append("templateImages", image);
    Axios({
      method: "post",
      url: "/api/v1/automate/addimage",
      data: formdata,
    })
      .then((res) => {
        const { Location } = res.data;
        setParaimage({
          ...paraimage,
          url: Location,
        });
        setisUploading(false);
      })
      .catch((error) => {
        setisUploading(false);
        if (error.response && error.response.data) {
          return toast.error(error.response.data.error);
        }
      });
  };
  const handleAddElement = (e) => {
    e.preventDefault();
    if (currentid) {
      setmainjson(
        mainjson.map((el) =>
          el.id === currentid
            ? {
                ...el,
                elementType,
                topic,
                company,
                week,
                content,
                image,
                paraimage,
                listcontents,
                pageBreak,
                bookmarkId,
                coverdate,
              }
            : el
        )
      );
    } else {
      setmainjson((v) => [
        ...v,
        {
          id: nanoid(),
          elementType,
          topic,
          company,
          week,
          content,
          image,
          paraimage,
          listcontents,
          pageBreak,
          bookmarkId,
          coverdate,
        },
      ]);
    }
    setBookmarkId("");
    setelementType("");
    settopic("");
    setcompany();
    setCoverDate();
    setweek();
    setcontent("");
    setimage("");
    setParaimage({
      url: "",
      size: 100,
      align: "left",
    });
    setlistcontents([]);
    setcurrentid();
    setPageBreak(false);
  };
  const deleteElement = () => {
    setmainjson(mainjson.filter((item) => item.id !== currentid));
    setcurrentid();
  };

  const [Docid, setDocId] = useState(nanoid(25));

  const saveDoc = async () => {
    setIsSaving(true);
    await Axios({
      url: "/api/v1/trendist/doc",
      method: "post",
      data: {
        id: Docid,
        data: mainjson,
        name: docName,
      },
    })
      .then((res) => {
        setIsSaving(false);
        if (res.data.success) {
          toast.success("data is saved");
        }
      })
      .catch(() => {
        setIsSaving(false);
        toast.error("something went wrong");
      });
  };

  const getDoc = async (id) => {
    await Axios({
      url: `/api/v1/trendist/deletedoc/${id}`,
      method: "get",
    })
      .then((res) => {
        const { data } = res.data;
        setDocName(data.name)
        setDocId(data.id)
        setmainjson(data.data)
      })
      .catch(() => {
        toast.error("something went wrong");
      });
  };

  useEffect(() => {
    let currentDate = new Date();
    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

    let weekNumber = Math.ceil(days / 7);
    setweek(weekNumber + 1);
  }, []);

  useEffect(() => {
    if (id) {
      getDoc(id);
    }
  }, [id]);
  if (isAdmin)
    return (
      <div className="row trendistmailform trendistEditorRoot">
        <div className="col-lg-4 col-md-5 col-sm-6 col-12 border border-dark">
          <InputElement
            {...{
              handleAddElement,
              week,
              setweek,
              topic,
              settopic,
              company,
              setcompany,
              elementType,
              setelementType,
              content,
              setcontent,
              currentid,
              mainjson,
              setcurrentid,
              deleteElement,
              handlePicUpload,
              image,
              setimage,
              listcontents,
              setlistcontents,
              newbullet,
              setnewbullet,
              paraimage,
              setParaimage,
              pageBreak,
              setPageBreak,
              bookmarkId,
              setBookmarkId,
              coverdate,
              setCoverDate,
              isUploading,
              handleImageUpload,
            }}
          />
        </div>
        <div className="col-lg-8 col-md-5 col-sm-6 col-12 border border-dark">
          <div className="d-flex align-items-center">
            {/* <button
              type="button"
              onClick={() => printDocument()}
              class="btn btn-primary"
            >
              Edit
            </button> */}
            <ReactToPrint
              trigger={() => (
                <button className="btn btn-success mr-40">Print</button>
              )}
              content={() => ref.current}
            />
            <input
              type="text"
              className="flex-grow-1 body1"
              placeholder="document name"
              style={{
                border: "none",
                borderBottom: "1px solid black",
                padding: "8px",
                outline: "none",
              }}
              value={docName}
              onChange={(e) => setDocName(e.target.value)}
            />
            {isSaving ? (
              <p>
                <strong>Saving...</strong>
              </p>
            ) : (
              <button
                className="btn btn-secondary ml-40 mr-40"
                onClick={saveDoc}
              >
                Save
              </button>
            )}

            {/* <button
              type="button "
              onClick={() => printDocument()}
              class="btn btn-success ms-2"
            >
              Print
            </button> */}
          </div>
          <Outputpage
            setcurrentid={setcurrentid}
            mainjson={mainjson}
            printRef={ref}
          />
        </div>
      </div>
    );
};

export default Editor;
