import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader";
import { useAuth } from "../../Utils/Auth";

const Check = () => {
  const navigate = useNavigate();
  const { getUser, isUser, isUserLoading, topicGlobal } = useAuth();
  useEffect(() => {
    getUser('1');
    if (isUser) {
      navigate(`/dashboard/${topicGlobal._id}`);
    }
    // react-hooks/exhaustive-deps
  }, [isUserLoading, isUser]);
  return (
    <div className="v_center">
      <Loader />
    </div>
  );
};

export default Check;
