import { CircularProgress, Drawer } from '@mui/material'
import React, { useState } from 'react'
import BlogsImages from '../Admin/BlogsImages'
import { Close } from '@mui/icons-material'
import { toast } from 'react-toastify'
import axios from 'axios'

const EditorStartData = ({
  setEditorState,
  coverTitle,
  setCoverTitle,
  coverImage,
  setCoverImage,
  id,
  setId,
  category,
  setCategory,
  industry,
  setIndustry,
  location,
  setLocation,
  link,
  setLink
}) => {
  const [state, setState] = useState(false)
  const toggleDrawer = open => setState(open)
  const [disabled, setDisabled] = useState(false)

  const handleNext = async () => {
    // if (!coverImage || !coverTitle) {
    //   return toast("complete all the fields");
    // }
    setDisabled(true)
    await axios({
      // url: `/api/v1/blog/create`,
      url: `https://backend.zefyron.com/api/blog/publish/trendist/cc76c734-8954-4350-a2fe-8b3b88c3c48c`,
      method: 'post',
      data: {
        Title: coverTitle,
        CoverImage: coverImage,
        // CoverImage:
        //   "https://zefyron-blogs.s3.eu-central-1.amazonaws.com/blogcover/6BEArjcsMVCX74hyxPH_I.png",
        id: id,
        Category: category,
        location,
        industry
      }
    })
      .then(res => {
        const { data } = res.data
        setDisabled(false)
        setEditorState(1)
        setId(data._id)
      })
      .catch(err => {
        setDisabled(false)
        console.log(err)
        toast.error('Error Occured')
      })
  }

  const handleImage = url => {
    setCoverImage(url)
    toggleDrawer(false)
  }

  return (
    <div>
      <div className='admin-dashboard-input mb-24'>
        <label htmlFor='cover-title' className='title mb-8'>
          Cover title
        </label>
        <input
          type='text'
          className='body1'
          value={coverTitle}
          onChange={e => setCoverTitle(e.target.value)}
        />
      </div>
      {coverImage ? (
        <div className='mb-24 position-relative'>
          <img src={coverImage} alt='cover' className='img-fluid' />
          <button
            className='position-absolute top-0 z-index-1 zefy_CTA_01_small_bg_black'
            style={{ right: 0 }}
            onClick={() => setCoverImage('')}
          >
            <Close />
          </button>
        </div>
      ) : (
        <div className='admin-dashboard-input mb-24'>
          <label
            htmlFor='cover-image'
            className='title mb-8 blog_chooseCoverImage'
            onClick={() => toggleDrawer(true)}
          >
            Choose Cover Image
          </label>
        </div>
      )}
      <div className='admin-dashboard-input mb-24'>
        <label htmlFor='cover-title' className='title mb-8'>
          Categories
        </label>
        <select
          name='categories'
          value={category}
          onChange={e => setCategory(e.target.value)}
          className='body1'
        >
          <option value=''>Select</option>
          <option value='Trends'>Trends</option>
          <option value='Cryptocurrencies and Blockchain'>
            Cryptocurrencies and Blockchain
          </option>
          <option value='Opinion & Blogs'>Opinion & Blogs</option>
          <option value='Finance'>Finance</option>
          <option value='Top10'>Top10</option>
        </select>
      </div>
      <div className='row'>
        <div className='col-6'>
          <div className='admin-dashboard-input mb-24'>
            <label htmlFor='Industry' className='title mb-8'>
              Industry
            </label>
            <input
              type='text'
              className='body1'
              value={industry}
              onChange={e => setIndustry(e.target.value)}
            />
          </div>
        </div>
        <div className='col-6'>
          <div className='admin-dashboard-input mb-24'>
            <label htmlFor='Location' className='title mb-8'>
              Location
            </label>
            <input
              type='text'
              className='body1'
              value={location}
              onChange={e => setLocation(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div>
        <div className='admin-dashboard-input mb-24'>
          <label htmlFor='Link' className='title mb-8'>
            Link
          </label>
          <input
            type='text'
            className='body1'
            value={link}
            onChange={e => setLink(e.target.value)}
          />
        </div>
      </div>

      <button
        className='zefy_CTA_01_small_bg_black mb-40'
        onClick={() => handleNext()}
        disabled={disabled}
      >
        {disabled ? (
          <CircularProgress color='warning' size='sm' value={38} />
        ) : (
          'Next'
        )}
      </button>

      <Drawer
        anchor={'right'}
        open={state}
        onClose={() => toggleDrawer(false)}
        className='blogs-images-drawer'
      >
        <BlogsImages onClickFunc={handleImage} />
      </Drawer>
    </div>
  )
}

export default EditorStartData
