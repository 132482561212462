import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/Images/logo.png";
import { useAuth } from "../../Utils/Auth";
import "./index.css";

const WeekNavbar = ({redir}) => {
  const [topicname, setTopicName] = useState();
  const { topicGlobal } = useAuth();
  useEffect(() => {
    if (topicGlobal) {
      if (String(topicGlobal.name).toLowerCase().includes("automotive")) {
        setTopicName("automotive");
      } else if (
        String(topicGlobal.name).toLowerCase().includes("sustainability")
      ) {
        setTopicName("sustainability");
      } else if (
        String(topicGlobal.name).toLowerCase().includes("measurement")
      ) {
        setTopicName("measurement");
      }
    }
  }, [topicGlobal]);
  return (
    <div className="admin-navbar-root">
      <div className="container">
        <div className="admin-navbar-main d-flex justify-content-between align-items-center">
          <div>
            <Link to={redir ? `/wvib/${redir}`: `/wvib/${topicname}`}>
              <img src={Logo} alt="logo" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeekNavbar;
