import React from "react";
import Logo from "../../../../Assets/Images/logo.png";

const MainHeading = ({ json }) => {
  return (
    <div className="basicMargin">
      <div className="d-flex flex-column justify-content-center align-items-center mainHeadingRoot">
        <div>
          <img src={Logo} alt="logo" />
        </div>
        <p className="mt-24 editorTitle1">{`${json.company} | ${json.topic} | Week ${json.week} | 2023`}</p>
      </div>
    </div>
  );
};

export default MainHeading;
