/** @format */

import React from "react";
import "./index.css";
const Loader = () => {
    return (
        <div>
            <div className='lds-roller'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Loader;